import React from 'react';
import Nav from './Nav';
import {
    Page,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Link,
    Toolbar,
    Block,
    BlockTitle,
    List,
    ListItem,
    Row,
    Col,
    Button
} from 'framework7-react';

export default () => (
  <Page>

  <Nav
    title="Health History"
    active="health_history"
  ></Nav>
  <Block className="blue health-history-block">

          <BlockTitle className="white block-title">Health History <span><i className="icon icon-search"></i></span></BlockTitle>

          <div className="health-card">
            <span className="card-title date-callout">Jan 4, '19</span>
            <span className="card-title type-callout">Activity</span>

            <div className="card-content row">
              <div className="col-50 row border-right">
                <div className="card-stat col-60">
                  <span className="stat-icon"><img src="assets/images/green-search.png"/></span>
                   <div className="text-wrapper">
                    <span className="stat-label">145 lbs</span>
                    <span className="stat-reading-type">Weight</span>
                   </div>
                </div>
                <div className="card-stat col-40">
                  <span className="stat-label">26.5</span>
                  <span className="stat-reading-type">BMI</span>
                </div>
              </div>
              <div className="col-50 row">
                <div className="card-stat col-50">
                  <span className="stat-icon"><img src="assets/images/run.png"/></span>
                   <div className="text-wrapper">
                    <span className="stat-label">45:52</span>
                    <span className="stat-reading-type">Run</span>
                   </div>
                </div>
                <div className="card-stat col-50">
                  <span className="stat-label">6:49</span>
                  <span className="stat-reading-type">Mile Time</span>
                </div>
              </div>
            </div>
          </div>

          <div className="health-card lab-results">
            <span className="card-title date-callout">Dec 22, '18</span>
            <span className="card-title type-callout">Test Results</span>

            <div className="card-content row">
              <div className="col-40 row border-right">
                <div className="card-stat col-100">
                  <span className="stat-label primary">Quest Labs</span>
                  <span className="stat-reading-type">Ordered by</span>
                  <div className="dr-row">
                    <span className="stat-icon"><img src="assets/images/dr.png"/></span>
                    <span className="stat-label">Dr. Allen R. Pickett</span>
                  </div>
                </div>
              </div>
              <div className="col-60 row wrap">
                <div className="card-stat col-50">
                  <span className="stat-label">85 mg/dl</span>
                  <span className="stat-reading-type">Glucose</span>
                </div>
                <div className="card-stat col-50">
                  <span className="stat-label">190 mg/dl</span>
                  <span className="stat-reading-type">Cholesterol</span>
                </div>
                <div className="card-stat col-50">
                  <span className="stat-label">90/58 mg/dl</span>
                  <span className="stat-reading-type">LDL/HDL</span>
                </div>
                <div className="card-stat col-50">
                  <span className="stat-label">95 mg/dl</span>
                  <span className="stat-reading-type">Triglycerides</span>
                </div>
              </div>
            </div>
          </div>

          <div className="health-card">
            <span className="card-title date-callout">Dec 5, '18</span>
            <span className="card-title type-callout">Doctor Office Visit</span>

            <div className="card-content row">
              <div className="col-40 row border-right">
                <div className="card-stat col-100">
                  <div className="dr-row pusher">
                    <span className="stat-icon"><img src="assets/images/dr.png"/></span>
                    <span className="stat-label">Dr. Allen R. Pickett</span>
                  </div>
                  <span className="stat-reading-type">Purpose</span>
                  <span className="stat-label primary">Annual Physical</span>
                </div>
              </div>
              <div className="col-60 row wrap checklist">
                <div className="card-stat col-100 checklist-header">
                  <span className="stat-reading-type gah">Follow Up Checklist</span>
                  <span className="stat-header gah">1/5</span>
                </div>
                <div className="card-stat col-100">
                  <ListItem checkbox defaultChecked className="stat-label">Lab Test</ListItem>
                </div>
                <div className="card-stat col-100">
                  <ListItem checkbox className="stat-label">Schedule Cardiologist Visit</ListItem>
                </div>
                <div className="card-stat col-100">
                  <ListItem checkbox className="stat-label">3 More items...</ListItem>
                </div>
              </div>
            </div>
          </div>

         <div className="health-card">
            <span className="card-title date-callout">Nov 21, '18</span>
            <span className="card-title type-callout">Activity</span>

            <div className="card-content row">
              <div className="col-50 row border-right">
                <div className="card-stat col-60">
                  <span className="stat-icon"><img src="assets/images/green-search.png"/></span>
                   <div className="text-wrapper">
                    <span className="stat-label">144 lbs</span>
                    <span className="stat-reading-type">Weight</span>
                   </div>
                </div>
                <div className="card-stat col-40">
                  <span className="stat-label">26.7</span>
                  <span className="stat-reading-type">BMI</span>
                </div>
              </div>
              <div className="col-50 row">
                <div className="card-stat col-50">
                  <span className="stat-icon"><img src="assets/images/run.png"/></span>
                   <div className="text-wrapper">
                    <span className="stat-label">47:13</span>
                    <span className="stat-reading-type">Run</span>
                   </div>
                </div>
                <div className="card-stat col-50">
                  <span className="stat-label">7:18</span>
                  <span className="stat-reading-type">Steps / Wk</span>
                </div>
              </div>
            </div>
          </div>
         
        
        </Block>
    <BlockTitle>History</BlockTitle>
    <List>
      <ListItem className="nav-item" link="" title="All Events"></ListItem>
      <ListItem className="nav-item" link="" title="Lab Results"></ListItem>
      <ListItem className="nav-item" link="" title="Office Visits"></ListItem>
    </List>

    <BlockTitle>Records</BlockTitle>
    <List>
      <ListItem className="nav-item" link="" title="Allergies"></ListItem>
      <ListItem className="nav-item" link="" title="Diagnoses"></ListItem>
      <ListItem className="nav-item" link="" title="Immunizations"></ListItem>
      <ListItem className="nav-item" link="" title="Medications"></ListItem>
      <ListItem className="nav-item" link="" title="Vitals"></ListItem>
    </List>
  </Page>
);
