import React from 'react';
import Nav from './Nav';
import {
    Page,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Link,
    Toolbar,
    Block,
    BlockTitle,
    List,
    ListItem,
    Row,
    Searchbar,
    Col,
    Button
} from 'framework7-react';

export default () => (
  <Page>
    <Nav
    title="Medications"
    active="pregnancy"
  ></Nav>

    <Block className="blue-block">
      <Searchbar 
            type="search" 
            placeholder="Ibu..." 
            clearButton={true} />
    </Block>


    <List className="no-margin">
      <ListItem className="nav-item" link="" title="Children's Ibuprofen"></ListItem>
      <ListItem className="nav-item" link="" title="Hydrocodone-Ibuprofen"></ListItem>
      <ListItem className="nav-item" link="" title="Ibuprofen PM"></ListItem>
      <ListItem className="nav-item" link="" title="Ibuprofen IB"></ListItem>
      <ListItem className="nav-item" link="" title="Vicoprofen"></ListItem>
      <ListItem className="nav-item" link="" title="Advil"></ListItem>
      <ListItem className="nav-item" link="" title="Motrin"></ListItem>
    </List>
  </Page>
);
