import React, { Component } from 'react';
import { Page, Navbar, Block, Link, ListItem, ListInput, Icon, List, Toolbar, Radio, Button } from 'framework7-react';
import Nav from './Nav';

export default class Pay extends Component {
  constructor(props) {
    super(props);

    this.togglePaymentType = this.togglePaymentType.bind(this);
    this.confirmPay = this.confirmPay.bind(this);
    this.showAccountCreate = this.showAccountCreate.bind(this);

    this.state = {
      ... this.props
    };
  }

  componentDidUpdate() {
    this.state = {
      ... this.props
    };
  }

  confirmPay(state) {
    this.$f7router.navigate('/paid/', {
      props: {
        ... state
      }
    })
  }

  showAccountCreate() {
    this.setState({
      'showAccountCreation': true
    })
    this.forceUpdate();
  }

  togglePaymentType(which) {
    let newTypes = this.state.paymentTypes;
    for(let i = 0; i < newTypes.length; i++) {
      if(newTypes[i].dataName === which) {
        newTypes[i].checked = 'checked'
      } else {
        newTypes[i].checked = '';
      }
    }
    this.setState({
      paymentType: which,
      paymentTypes: newTypes
    });
  }

  render() {
    const {
      drname,
      paymentStatus
    } = this.state;
    let self = this;
    return (
      <Page className="pay-detail bill-detail">
        <Nav
          title={"Make Payment: "+drname}
          active="pregnancy"
        ></Nav>


       <Block className="services-list-block">
         <List className="services-list no-margin np-padding list-meta">
         <div className="block-title block-title payment-title">How Would You Like to Pay?</div>

         <List className="payment-options-list">
         {this.state.paymentTypes.map(type => (
            <ListItem onClick={(e) => self.togglePaymentType(type.dataName)} title={type.name}><Radio name="payment-type" value={type.dataName} checked={type.checked} /></ListItem>
           ))}
        </List>

        
        {this.state.paymentType === 'card' && 
          <List noHairlinesMd>
            <ListInput
              type="number"
              placeholder="Card Number"
              clearButton
            >
              <Icon icon="icon-id-cards" slot="media"/>
            </ListInput>
            <ListItem className="row">
              <ListInput
                className="col-65"
                type="date"
                placeholder="MM/DD/YY"
                clearButton
              >
                <Icon icon="icon-calendar" slot="media"/>
              </ListInput>
              <ListInput
                className="col-40"
                type="number"
                placeholder="CVV"
                max="3"
                clearButton
              >
                <Icon icon="icon-key" slot="media"/>
              </ListInput>
             </ListItem>
             <ListItem className="save-card-toggle" checkbox title="Save this card for future payments" name="demo-checkbox" defaultChecked></ListItem>
          </List>
        }
        {this.state.paymentType === 'hsa' && 
          <ListItem className="hsa">
             <span className="small meta">The total bill amount will be deducted from your combined HSA available balance of $1,113.12.</span>
          </ListItem>
        }
        {this.state.paymentType === 'account' && 
        <ListItem className="bank-account">
          <ListInput
            label="Select Saved Account"
            type="select"
            defaultValue="BoFA - xxxx 3678"
            placeholder="Please choose..."
          >
            <Icon icon="demo-list-icon" slot="media"/>
            <option value="BoFA - xxxx 3678">BoFA - xxxx 3678</option>
            <option value="Chase">Chase - xxxx 1245</option>
          </ListInput>

          {!this.state.showAccountCreation &&
            <div class="item-title item-label add-new-account" onClick={() => self.showAccountCreate()}>Or, Add a new account +</div>
          }

          {this.state.showAccountCreation === true &&
            <div>
            <ListInput
              type="number"
              placeholder="Account Number"
              clearButton
            >
              <Icon icon="icon-id-cards" slot="media"/>
            </ListInput>
            <ListInput
              type="number"
              placeholder="Routing Number"
              clearButton
            >
              <Icon icon="icon-id-cards" slot="media"/>
            </ListInput>
            </div>
          }
          </ListItem>
        }
 
        </List>
       </Block>

       {this.state.paymentStatus != 'paid' && this.state.paymentStatus != 'pending' &&
         <Toolbar className="payment">
           <div className="payment-toolbar-amount row">
             <div className="col-50">
              <span class="provider-name list-item bold">Total Due: </span>
            </div>
            <div className="col-50 t-align-right">
              <span className="row-amount bill-amount">{this.state.amount ? this.state.amount : '$157.39'}</span>
            </div>
           </div>
           {/*<Button fill className="payment-button" onClick={() => self.confirmPay(this.state)}>Confirm</Button>*/}
           {<Button fill className="payment-button" href="/paid/">Confirm</Button>}
        </Toolbar>
      }
      </Page>
    );
  }
}

Pay.defaultProps = {
  paymentType: 'card',
  showAccountCreation: false,
  paymentTypes: [
      {
        name: 'Credit/Debit',
        dataName: 'card',
        checked: 'checked'
      },
      {
        name: 'Bank Account',
        dataName: 'account',
        checked: ''
      },
      {
        name: 'HSA Balance ($1,113.12)',
        dataName: 'hsa',
        checked: ''
      }
    ]
}