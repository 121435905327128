import React, {Component} from 'react';
import props from "../globals/globals";
import PropTypes from 'prop-types';
import {
    List,
    ListItem,
    Row,
    Col,
} from 'framework7-react';


export default class ListMeta extends Component {
	constructor(props) {
		super(props);

		let selfRef = this;

		this.goToBill = this.goToBill.bind(this);
	};

	goToBill(which, goTo) {
		goTo.$f7router.navigate('/bill/', {
			props: {
				goTo,
				... which
			}
		})
	}

	render() {
		const {
			listData,
			goTo
		} = this.props;
		let self = this;
		return (
			<List className="no-margin list-meta">
			{listData.map(function(row, key){
				return (
		      		<ListItem 
		      			key={key}
		      			className="nav-item row"
		      			onClick={() => self.goToBill(row, goTo)}
		      			>
		      			<div className="col-50">
		      				<span className="bold list-label">{row.date}</span>
		      				<span class="provider-name list-item">{row.drname}</span>
		      			</div>
		      			<div className="col-50 t-align-right">
		      				<span className={"list-meta "+row.metaClass}>{row.metaLabel}</span>
		      				<span className="row-amount bill-amount">
		      				{row.icon &&
		      					<i className={"row-icon fa fa-"+row.icon}></i>
		      				}
		      				{row.amount}</span>
		      			</div>
		      		</ListItem>
		      	);
		      })
			}
		    </List>
		);
	};
};

ListMeta.defaultProps = {
	listData:  []
}

// export default MapView;