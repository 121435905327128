import React, {Component} from 'react';
import {
    Page,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Link,
    Toolbar,
    Block,
    BlockTitle,
    List,
    ListItem,
    Row,
    Col,
    Button
} from 'framework7-react';

export default class Stats extends Component {
  constructor(props) {
    super(props);

    this.props = props;
  }

  render() {
    const {
      statsData
    } = this.props;

    return (
      <div className="row grid stats">
      {statsData.map(function(stat, key) {
        return (
            <div className={'col-'+stat.gridSize+' stat'} key={key}>
              <div className="stat-label">{stat.reading}</div>
              {stat.icon && 
                <div className="stat-icon"><i className={'fa fa-'+stat.icon}></i></div>
              }
              <div className="stat-value">{stat.readingType}</div>
              <div className="stat-meta">{stat.meta}</div>
            </div>
          )
      })}
      </div>
    )
  }
};
