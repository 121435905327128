import React, {Component} from 'react';
import Nav from './Nav';
import {
    Page,
    Link,
    Toolbar,
    Block,
    BlockTitle,
    List,
    ListItem,
    Row,
    Badge,
    Col,
    Card,
    Button
} from 'framework7-react';

import props from "../globals/globals";

import FamilySwitcher from "./FamilySwitcher";

class HomePage extends Component {

  componentWillMount() {
    this.$f7.panel.close('left');
  }

  render() {
    // set it up
    window.app = this;

    return (
      <Page className="home">
        <Nav
        logo={true}
        title="Hello, Jane!"
        active="home"
      ></Nav>
       {/* <FamilySwitcher 
          family={props.family}
          controller={this}
        ></FamilySwitcher>*/}

         <List className="nav-list home-accordion-group">
          <ListItem className="nav-item accordion active" link="/bills/" title="View &amp; Pay Bills"><i className='icon-cost-paper'></i>
            <div className="accordion-content bills-page">
              <Block className="no-margin marg-top no-pad-top">
                  <div className="row due">
                    <div className="col-50 border-right">
                      <span class="provider-name list-item">$506.21</span>
                      <span class="list-label list-item meta-low">Total Due</span>
                    </div>
                    <div className="col-50">
                      <span class="provider-name list-item warning"><i className="fa fa-exclamation-triangle red bill-due-warning"></i> $76.54</span>
                      <span class="list-label list-item meta-low">Past 30 Days</span>
                    </div>
                  </div>
                  <Button fill className="payment-button" link href="/bills/">VIEW BILLS <Badge>3</Badge></Button>
                  <Card
                    title="HSA AVAILABLE TO SPEND"
                    className="spending-card first"
                  >
                    <div className="row">
                    <div className="col-50 border-right">
                      <span className="bigger dollar-amount">$345.00</span>
                      <span>Fidelity</span>
                    </div>
                    <div className="col-50">
                      <span className="bigger dollar-amount">$768.12</span>
                      <span>HealthEquity</span>
                    </div>
                    </div>
                  </Card>
                  <Card
                    title="SPENDING THIS YEAR"
                    className="spending-card"
                  >
                    <div className="row abs-reader">
                    <div className="reader"></div>

                    <div className="info-tip">i</div>
                    <div className="col-33 blue t-left">
                      <span className="dollar-amount">$2,828</span>
                      <span>Spent</span>
                    </div>
                    <div className="col-33">
                      <span className="dollar-amount">$3,600</span>
                      <span>Deductible</span>
                    </div>
                    <div className="col-33 t-right">
                      <span className="dollar-amount">$6,500</span>
                      <span>Out-of-Pocket max</span>
                    </div>
                    </div>
                  </Card>
              </Block>
            </div>
          </ListItem>
          <ListItem className="nav-item accordion" link="/records/" title="View Your Health History"><i className='icon-heart'></i>
            <div className="accordion-content">

            </div>
          </ListItem>
          <ListItem className="nav-item accordion" link="/forms/" title="Send Feedback"><i className='icon-records'></i>
            <div className="accordion-content">

            </div>
          </ListItem>
        </List>
       
      </Page>
      );
  }
};

export default HomePage;