import React, { Component } from 'react';
import { Page, Navbar, Block, Link, ListItem, List, Toolbar, Button } from 'framework7-react';
import Nav from './Nav';

export default class Bill extends Component {
  Constructor(props) {

    this.goToPay = this.goToPay.bind(this);
  }
  goToPay(which, goTo) {
    // console.log(which, goTo)
    this.$f7router.navigate('/pay/', {
      props: {
        ... which
      }
    })
  }

  render() {
    const {
      drname,
      paymentStatus,
      goTo
    } = this.props;
    let self = this;
    return (
      <Page className="bill-detail">
        <Nav
          title={"Bill: "+drname}
          active="pregnancy"
        ></Nav>
       <div className={"status-banner "+paymentStatus}>{paymentStatus}</div>

       <Block className="bill-detail-header row">
         <div className="col-70">
           <span className="small meta">{this.props.date}</span>
           <div className="provider-name">{this.props.drname}</div>
           <div className="small meta">1601 Park Place Avenue<br/>San Francisco, CA 94016<br/>(415)555-1234</div>
         </div>
         <div className="col-30 due">
           <span class="provider-name list-item warning"><i className="fa fa-exclamation-triangle red bill-due-warning"></i> $76.54</span>
           <span class="list-label list-item meta-low">Past 30 Days</span>
         </div>
       </Block>

       <Button fill className="payment-button tight" link href="/pay/">PAYMENT OPTIONS </Button>

       <Block className="services-list-block">
         <List className="services-list no-margin np-padding list-meta">
        {this.props.servicesList && <div className="block-title block-title services-title">Services</div> }
         {this.props.servicesList && this.props.servicesList.map(service => (
          <ListItem className={"nav-item row "+service.type}>
            <div className="col-50">
              <span class="provider-name list-item">{service.name}</span>
            </div>
            <div className="col-50 t-align-right">
              <span className="row-amount bill-amount">{service.type === 'payment' && '-'}{service.cost}</span>
            </div>
          </ListItem>
          ))}

          <ListItem className="nav-item row total">
            <div className="col-50">
              <span class="provider-name list-item bold">Billed Amount </span>
            </div>
            <div className="col-50 t-align-right">
              <span className="row-amount bill-amount">{this.props.totalAmount}</span>
            </div>
          </ListItem>
        </List>
       </Block>

       <Block className="services-list-block payments">
         <List className="services-list no-margin np-padding list-meta">
         {this.props.debitsList && this.props.debitsList.map(service => (
          <ListItem className={"nav-item row "+service.type}>
            <div className="col-50">
              <span class="provider-name list-item">{service.name}</span>
            </div>
            <div className="col-50 t-align-right">
              <span className="row-amount bill-amount">{service.type === 'payment' && '-'}{service.cost}</span>
            </div>
          </ListItem>
          ))}
        </List>
       </Block>

       {this.props.paymentStatus === 'pending' &&
         <Block className="bill-detail-header">
           <span className="small meta">We are awaiting more information on this bill. Check back here, or if you would like we can send you a notification when the bill is ready to be paid.</span>
           <Link className="notification-prompt">Turn on Bill notifications now?</Link>
         </Block>
       }

       {this.props.paymentStatus != 'paid' && this.props.paymentStatus != 'pending' &&
         <Toolbar>
           {/*<Button fill className="payment-button" onClick={() => self.goToPay(self.props, goTo)}>Pay Now</Button>*/}
           {<Button fill className="payment-button" href="/pay/">Pay Now</Button>}
        </Toolbar>
      }
      </Page>
    );
  }
}