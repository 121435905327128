import React, { Component } from 'react';
import props from "../globals/globals";
import PropTypes from 'prop-types';
import axios from 'axios';
import Nav from './Nav';
import {
    Page,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Link,
    Toolbar,
    Block,
    BlockTitle,
    List,
    ListItem,
    Row,
    Col,
    Button,
    Input,
    Searchbar,
    Panel,
    View
} from 'framework7-react';

import MapView from "./Map";


let searchable = false;

export default class FindCare extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mapReady: true,
        // latLng: [pos.coords.longitude, pos.coords.latitude]
        latLng: [-122.2852, 37.8313],
      markers: [],
      searchString: 'doctor',
      searchTimeout: false,
      toolbarHidden: false
    };

    this.latLngGetter = this.latLngGetter.bind(this);
    this.getResults = this.getResults.bind(this);
    this.searchString = this.searchString.bind(this);
    this.assignSearchString = this.assignSearchString.bind(this);
    this.mainFilter = this.mainFilter.bind(this);
  };

  componentWillMount() {
    // this.setMap();
  }

  setMap() {
    // navigator.geolocation.getCurrentPosition(this.latLngGetter);
    this.latLngGetter();
  }

  latLngGetter(pos) {
      this.setState({
        mapReady: true,
        // latLng: [pos.coords.longitude, pos.coords.latitude]
        latLng: [-122.2852, 37.8313]
      })
      this.forceUpdate();
      // this.getResults();
  }

  searchString(event) {
    // let self = this;
    // console.log('called');

    // if(this.state.searchTimeout) {
    //   console.log('hellllo');
      // console.log(event.target.value);
      this.assignSearchString(event.target.value);
    
      // this.setState({
        // searchTimeout: false
      // });
    // }

    // setTimeout(function() {
      // self.setState({
        // searchTimeout: true
      // });
      // self.searchString(event.target.value);
    // }, 4000);
  }

  mainFilter(str) {
    this.setState({
      toolbarHidden: true
    }, this.assignSearchString(str));
  }

  assignSearchString(str) {
    this.setState({
      mapReady: false,
      searchString: str
    }, function() {
      this.getResults();
    });
  }

  getResults() {
    let latLng = this.state.latLng;
    let str = this.state.searchString;

    axios.get(`https://maps.googleapis.com/maps/api/place/textsearch/json`, {
      params: {
        'fields': 'formatted_address, id, photos, ratings, geometry, name',
        'query': this.state.searchString,
        'location': ''+latLng[1]+','+latLng[0],
        'radius': 2,
        'key': props.googlePlacesKey
      }
    })
    .then(res => {
      const posts = res.data.results.map(obj => obj);
      this.setState({ markers: posts , mapReady: true});
    });
  }

  componentWillMount() {
    this.$f7.panel.close('left');
  }

  render() {
     const {
      latLng,
      mapReady,
      markers,
      searchString,
      toolbarHidden
    } = this.state;
    return (
      <Page className="find-care">
       {/* <Navbar>
          <NavLeft>
            <Link iconIos="f7:menu" iconMd="material:menu" panelOpen="left"></Link>
          </NavLeft>
          <NavTitle>Find Care</NavTitle>
          <NavRight>
            <Link iconIos="f7:menu" iconMd="material:menu" panelOpen="right"></Link>
          </NavRight> 
        </Navbar>*/}

        <Nav
          title="Find Care"
          active="pregnancy"
        ></Nav>

        <Block strong className="blue">
          <Button fill white big raised>Dial 911</Button>
          <Searchbar 
            type="search" 
            placeholder="Search doctors, hospitals, services, and more..." 
            onBlur={this.searchString.bind(this)}
            clearButton={true} />

            {/*<a fill raised href="/map-panel/">Bottom Panel</a>*/}
        </Block>

        {mapReady && (
          <MapView 
            latLng={latLng} 
            markers={markers}
          />
        )}

     {/*  <Panel bottom reveal active themeLight hidden={!toolbarHidden}>
         <View url="/map-panel/" />
       </Panel>*/}

       <Toolbar hidden={toolbarHidden} className="row">
          <Link 
            className="col-50"
            >Emergency Rooms</Link>
            {/*onClick={this.mainFilter.bind(this, 'emergency room')}*/}
          <Link 
            className="col-50"
            >Urgent Care</Link>
            {/*onClick={this.mainFilter.bind(this, 'urgent care')}*/}
          <Link 
            className="col-50"
            >Hospitals</Link>
            {/*onClick={this.mainFilter.bind(this, 'hospital')}*/}
          <Link 
            className="col-50"
            >Pharmacies</Link>
            {/*onClick={this.mainFilter.bind(this, 'pharmacy')}*/}
        </Toolbar>
      </Page>
    )
  };
};

FindCare.propTypes = {
  latLng: PropTypes.array
}