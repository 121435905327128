import React, {Component} from 'react';
import props from "../globals/globals";

import ReactMapboxGl, { Layer, Feature, Popup, Marker } from "react-mapbox-gl";
const MapBox = ReactMapboxGl({accessToken:props.mapBoxKey});

export default class MapView extends Component {
	constructor(props) {
		super(props);

		let selfRef = this;

		this.state = {
			activePopUp: false,
			popUp: {},
			center: selfRef.props.latLng
		};

		this.showPopUp = this.showPopUp.bind(this);
		this.changeMyLocation = this.changeMyLocation.bind(this);
	};

	showPopUp(pop) {
		this.setState({
			activePopUp: true, 
			popUp: this.props.markers[pop], 
			center: [this.props.markers[pop].geometry.location.lng, this.props.markers[pop].geometry.location.lat]
		});
	}

	changeMyLocation() {
		console.log('gonna change my location');
	}


	render() {
		const {
			latLng,
			markers,
			zoom,
			showPopUp
		} = this.props;

		const {
			activePopUp,
			popUp,
			center
		} = this.state;

		const self = this;
		console.log('got here');
		return (
		  <MapBox
		    style="mapbox://styles/mapbox/streets-v8"
		    zoom={zoom}
		    center={center}
		    containerStyle={{
		      height: "81%",
		      width: "100%"
		    }}>
		    <Marker
				key="your_location"
		  		coordinates={latLng}
		  		anchor="bottom"
		  		onClick={this.changeMyLocation}>
		  		<div class="pin icon-pin"></div>
			</Marker>
			{markers.map(function(pin, key){
				return ( 
					<Marker
						key={key}
				  		coordinates={[pin.geometry.location.lng, pin.geometry.location.lat]}
				  		anchor="top"
				  		onClick={self.showPopUp.bind(self, key)}>
				  		<div class="pin icon-pin-solid" data-row-item={key}><span>{key+1}</span></div>
					</Marker>
				)
			})}
			{activePopUp && (
	          <Popup key={popUp.id} coordinates={[popUp.geometry.location.lng, popUp.geometry.location.lat]}>
	              <div>{popUp.name}</div>
	              {/*<div><img alt="lorem" src={popUp.icon} /></div>*/}
	              <div>
	                {popUp.formatted_address}
	              </div>
	          </Popup>
	        )}
		  </MapBox>
		);
	}
};

MapView.defaultProps = {
	zoom: [12],
	latLng: [],
	markers: []
}

// export default MapView;