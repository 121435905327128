import React, {Component} from 'react';
import Nav from './Nav';
import {
    Page,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Link,
    Toolbar,
    Block,
    BlockTitle,
    List,
    ListItem,
    Row,
    Col,
    Button
} from 'framework7-react';

import props from "../globals/globals";

import Stats from "./Stats";

export default class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stats: [
        {
          reading: '140/90 mmHg',
          readingType: 'Blood Pressure',
          meta: '2 hrs ago',
          gridSize: '40'
        },
        {
          reading: '26.5',
          readingType: 'BMI',
          meta: '1 wk ago',
          gridSize: '30'
        },
        {
          reading: '6:49',
          readingType: 'Mile Time',
          meta: '1 Wk ago',
          gridSize: '30'
        },
        {
          reading: '4:1',
          readingType: 'Cholesterol Ratio',
          meta: '3 mo ago',
          gridSize: '40',
          icon: 'exclamation-triangle yellow'
        },
        {
          reading: '5.6%',
          readingType: 'HbA1c',
          meta: '3 mo ago',
          gridSize: '30'
        },
        {
          reading: '72 bpm',
          readingType: 'Resting Pulse',
          meta: 'Live',
          gridSize: '30'
        }
      ],
      healthHistory: [
        {
          'date': 'Jan 5, \'19',
          'type': 'Activity',
          'grid': '50',
          'children': [
            {
              'icon': 'icon-new_findcare_sm green-bg',
              'label': '145 lbs',
              'type': 'Weight',
              'grid': '50'
            },
            {
              'label': '26.5',
              'type': 'BMI',
              'grid': '50'
            },
          ]
        }
      ]
    };

    this.toggleAccordion = this.toggleAccordion.bind(this);
  }

  toggleAccordion() {
    this.setState({
      'accordionOpen': !this.state.accordionOpen
    })
    this.forceUpdate();
  }

  render() {
    const {
      stats,
      accordionOpen
    } = this.state;
    console.log(this.props);
    console.log(props);
    let self = this;
    var image = this.props.image ? this.props.image : 'jane.png';

    let personImageStyle = {
      'background-image': 'url(assets/images/'+image+')'
    }
    return (
      <Page className="profile-page">
       <Nav
        title="Health Profile"
        active="pregnancy"
      ></Nav>


        <Block className="profile-header no-margin">
        	<div className="profile-image" style={personImageStyle}></div>
            <div className="profile-meta">
              <div className="person-name">{this.props.name ? this.props.name + ' Flores' : 'Jane Flores'} <div className="profile-right-edits"><i className="icon-edit"></i><i className="icon-new icon-new_share_sm"></i></div></div>
              <div className="person-details">{this.props.gender ? this.props.gender : 'Female'}, {this.props.age ? this.props.age : '42'}, {this.props.height ? this.props.height : '5\'2"'}</div>
              <div className="person-details">{this.props.birthday ? this.props.birthday : 'Dec 10, 1976'}</div>
              <div className="person-details">{!this.props.child && 'Married'}</div>
              <br/>
              <div className="person-details">3258 Harbord Drive</div>
              <div className="person-details">Oakland, CA 94607</div>
              <div className="person-details">(510) 555-1933</div>

            </div>
        </Block>


       <Link className={!self.state.accordionOpen ? 'small stat-title accordion-control open' :  'small stat-title accordion-control'} onClick={() => self.toggleAccordion()}><i className="fa fa-caret-down"></i> TOP STATS</Link>
        <Block className={!self.state.accordionOpen ? 'profile-stats no-margin accordion collapsed' :  'profile-stats no-margin accordion'}>
          <Stats statsData={stats}></Stats>
        </Block>

        <List className="no-margin nav-list no-min">
          <ListItem className="nav-item" link="/insurance-details/" title="Insurance"><i className='icon-id-cards'></i></ListItem>
          <ListItem className="nav-item" link="/insurance-details/" title="Emergency Contacts"><i className='icon-new_contacts'></i></ListItem>
          <ListItem className="nav-item" link="/care-team/" title="Care Team"><i className='icon-new_careteam'></i></ListItem>
          <ListItem className="nav-item" link="/medications/" title="Medications"><i className='icon-medication'></i><span class="badge">1</span><span className="list-sub">Refill awaiting pickup</span></ListItem>
          <ListItem className="nav-item" link="/Login/" title="Login &amp; Password"><i className='icon-key'></i></ListItem>
          <ListItem className="nav-item" link="/settings/" title="Notification Settings"><i className='icon-new_settings_sm'></i></ListItem>
          {/*<ListItem className="nav-item" link="/appointments/" title="Appointments"><i className='icon-calendar'></i><span class="badge">2</span></ListItem>*/}
        </List>
        <Block className="blue health-history-block">

          <BlockTitle className="white block-title">Health History <span><i className="icon icon-search"></i></span></BlockTitle>

          <div className="health-card">
            <span className="card-title date-callout">Jan 4, '19</span>
            <span className="card-title type-callout">Activity</span>

            <div className="card-content row">
              <div className="col-50 row border-right">
                <div className="card-stat col-60">
                  <span className="stat-icon"><img src="assets/images/green-search.png"/></span>
                   <div className="text-wrapper">
                    <span className="stat-label">145 lbs</span>
                    <span className="stat-reading-type">Weight</span>
                   </div>
                </div>
                <div className="card-stat col-40">
                  <span className="stat-label">26.5</span>
                  <span className="stat-reading-type">BMI</span>
                </div>
              </div>
              <div className="col-50 row">
                <div className="card-stat col-50">
                  <span className="stat-icon"><img src="assets/images/run.png"/></span>
                   <div className="text-wrapper">
                    <span className="stat-label">45:52</span>
                    <span className="stat-reading-type">Run</span>
                   </div>
                </div>
                <div className="card-stat col-50">
                  <span className="stat-label">6:49</span>
                  <span className="stat-reading-type">Mile Time</span>
                </div>
              </div>
            </div>
          </div>

          <div className="health-card lab-results">
            <span className="card-title date-callout">Dec 22, '18</span>
            <span className="card-title type-callout">Test Results</span>

            <div className="card-content row">
              <div className="col-40 row border-right">
                <div className="card-stat col-100">
                  <span className="stat-label primary">Quest Labs</span>
                  <span className="stat-reading-type">Ordered by</span>
                  <div className="dr-row">
                    <span className="stat-icon"><img src="assets/images/dr.png"/></span>
                    <span className="stat-label">Dr. Allen R. Pickett</span>
                  </div>
                </div>
              </div>
              <div className="col-60 row wrap">
                <div className="card-stat col-50">
                  <span className="stat-label">85 mg/dl</span>
                  <span className="stat-reading-type">Glucose</span>
                </div>
                <div className="card-stat col-50">
                  <span className="stat-label">190 mg/dl</span>
                  <span className="stat-reading-type">Cholesterol</span>
                </div>
                <div className="card-stat col-50">
                  <span className="stat-label">90/58 mg/dl</span>
                  <span className="stat-reading-type">LDL/HDL</span>
                </div>
                <div className="card-stat col-50">
                  <span className="stat-label">95 mg/dl</span>
                  <span className="stat-reading-type">Triglycerides</span>
                </div>
              </div>
            </div>
          </div>

          <div className="health-card">
            <span className="card-title date-callout">Dec 5, '18</span>
            <span className="card-title type-callout">Doctor Office Visit</span>

            <div className="card-content row">
              <div className="col-40 row border-right">
                <div className="card-stat col-100">
                  <div className="dr-row pusher">
                    <span className="stat-icon"><img src="assets/images/dr.png"/></span>
                    <span className="stat-label">Dr. Allen R. Pickett</span>
                  </div>
                  <span className="stat-reading-type">Purpose</span>
                  <span className="stat-label primary">Annual Physical</span>
                </div>
              </div>
              <div className="col-60 row wrap checklist">
                <div className="card-stat col-100 checklist-header">
                  <span className="stat-reading-type gah">Follow Up Checklist</span>
                  <span className="stat-header gah">1/5</span>
                </div>
                <div className="card-stat col-100">
                  <ListItem checkbox defaultChecked className="stat-label">Lab Test</ListItem>
                </div>
                <div className="card-stat col-100">
                  <ListItem checkbox className="stat-label">Schedule Cardiologist Visit</ListItem>
                </div>
                <div className="card-stat col-100">
                  <ListItem checkbox className="stat-label">3 More items...</ListItem>
                </div>
              </div>
            </div>
          </div>

         <div className="health-card">
            <span className="card-title date-callout">Nov 21, '18</span>
            <span className="card-title type-callout">Activity</span>

            <div className="card-content row">
              <div className="col-50 row border-right">
                <div className="card-stat col-60">
                  <span className="stat-icon"><img src="assets/images/green-search.png"/></span>
                   <div className="text-wrapper">
                    <span className="stat-label">144 lbs</span>
                    <span className="stat-reading-type">Weight</span>
                   </div>
                </div>
                <div className="card-stat col-40">
                  <span className="stat-label">26.7</span>
                  <span className="stat-reading-type">BMI</span>
                </div>
              </div>
              <div className="col-50 row">
                <div className="card-stat col-50">
                  <span className="stat-icon"><img src="assets/images/run.png"/></span>
                   <div className="text-wrapper">
                    <span className="stat-label">47:13</span>
                    <span className="stat-reading-type">Run</span>
                   </div>
                </div>
                <div className="card-stat col-50">
                  <span className="stat-label">7:18</span>
                  <span className="stat-reading-type">Steps / Wk</span>
                </div>
              </div>
            </div>
          </div>
         
        
        </Block>
      </Page>
    )
  }
};

Profile.defaultProps = {
  accordionOpen: 'false'
}
