import React, {Component} from 'react';
import Nav from './Nav';
import {
    Page,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Link,
    Toolbar,
    Block,
    BlockTitle,
    Panel,
    List,
    ListItem,
} from 'framework7-react';

export default class MainMenu extends Component {
  Constructor(props) {
   this.onClickLink = this.onClickLink.bind(this);
  }

  onClickLink(link) {
    this.$f7.panel.close('left');
    this.$f7.router.navigate(link);
  }

  render() {
    return (
    <Panel className="nav-panel" left reveal>
        <Page>
          <Navbar title="Health App" />
          <List className="nav-list main-nav">
            <ListItem className="nav-item" link title="Home" onClick={() => this.onClickLink("/")}><i><svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1"> <g id="Flows-(Revised-Colors)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g id="Menu" transform="translate(-25.000000, -87.000000)" stroke="#0D0555">  <g id="Group" transform="translate(0.000000, 65.000000)">  <g id="Group-2">  <g id="List" transform="translate(0.000000, 1.000000)">   <g id="Row-Copy-7">   <g id="Rectangle-2" transform="translate(25.000000, 21.000000)">   <path d="M0.5,4.12146127 L0.5,17.5 L6.5,17.5 L6.5,9.92105263 L11.5,9.92105263 L11.5,17.5 L17.5,17.5 L17.5,4.12146127 L9,0.542513898 L0.5,4.12146127 Z" id="Rectangle"></path>   </g>   </g>  </g>  </g>  </g> </g> </g> </svg></i></ListItem>
            <ListItem className="nav-item" link title="Find Care" onClick={() => this.onClickLink('/find-care/')}><i className="icon-new icon-new_findcare_lg"></i></ListItem>
            <ListItem className="nav-item" link title="Estimate Costs" onClick={() => this.onClickLink('/estimates/')}><i className="icon-new icon-new_paper_dollar"></i></ListItem>
            <ListItem className="nav-item" link title="Health History" onClick={() => this.onClickLink('/records/')}><i className="icon-new icon-new_history_lg"></i></ListItem>
            
            <ListItem className="nav-item" link title="Bills &amp; Payments" onClick={() => this.onClickLink('/bills/')}><i className='icon-cost-paper'></i></ListItem>
            <ListItem className="nav-item" link title="Care Team" onClick={() => this.onClickLink("/care-team/")}><i className='icon-new_careteam'></i></ListItem>
            <ListItem className="nav-item" link title="Medications" onClick={() => this.onClickLink("/medications/")}><i className='icon-new icon-new_medications_sm'></i></ListItem>
            {/*<ListItem link title="Profile" link="/profile/"></ListItem>*/}
            {/*<ListItem link title="Auto-fill Medical Forms" link="/forms/"></ListItem>*/}
          </List>
        </Page>
      </Panel>
  )
  }
};
