import React, {Component} from 'react';
import {Link, Block} from 'framework7-react';

import Hammer from 'react-hammerjs';

import props from "../globals/globals";

export default class FamilySwitcher extends Component {
	state = {
    	'activeMember': props.activeMember,
    	'transX': 1,
    	'isDragging': true
  	};
  Constructor(props) {
  	this.state = this.props;

  	this.handleSwipeLeft = this.handleSwipeLeft.bind(this);
  	this.handleSwipeRight = this.handleSwipeRight.bind(this);
  	
  	this.handlePan = this.handlePan.bind(this);
  	this.panEnder = this.panEnder.bind(this);
  	

  	this.goToProfile = this.goToProfile.bind(this);
  }

  handleSwipeLeft(event) {
  	if(this.state.activeMember <= 3) {
  		let active = this.state.activeMember+1;
  	 	this.setState({'activeMember': active})
  	 	props.activeMember = active;
  	}
  }
  handleSwipeRight(event) {
  	if(this.state.activeMember >= 1) {
  		let active = this.state.activeMember-1;
  	 	this.setState({'activeMember': active})
  	 	props.activeMember = active;
  	}
  }
  handlePan(event) {
  	if(!this.state.isDragging) {
  		this.setState({'isDragging': true});
  		let lastPanPos = this.state.transX
  	}

  	this.setState({
  		'transX': event.deltaX + this.state.transX
  	});

  }

  panEnder(e) {
  	this.state.isDragging = false;

  	if(e.additionalEvent === 'panleft' || e.offsetDirection === 2) { // left / prev
      if(this.state.activeMember+1 < 3) { // -1 to avoid the + add going to active
        this.handleSwipeLeft();
      }
    } else if(e.additionalEvent === 'panright' || e.offsetDirection === 4) { // right / next
        if(this.state.activeMember - 1 >= 0) {
          this.handleSwipeRight();
        }
    }
  }

  goToProfile(which, i) {
  	props.activeMember = i;
  	this.setState({'activeMember': i});
  	this.props.controller.$f7router.navigate('/profile/', {
  		props: {
  			... which
  		}
  	})
  }

  render() {
  	const {
  		family,
  		activeMember
  	} = this.props;
  	var i = 0;
  	const self = this;


  	let containerStyle = {
  		'transform': 'translateX(-'+(92 * self.state.activeMember)+'px)'
  	};

	return (
		<section className="family">
		<Hammer 
			onSwipeLeft={(e) => this.handleSwipeLeft(e)} 
			onSwipeRight={(e) => this.handleSwipeRight(e)}
		>
			<div 
				className="container" 
				style={containerStyle}
			>
				{family.map((a, i) => {
					return (
						<div 
							key={a.name}
							className={i === self.state.activeMember ? 'member active' : 'member'} 
							data-name={a.name}
						>
							<a onClick={() => this.goToProfile(a, i)} className="inner" style={{backgroundImage: `url(assets/images/`+a.image+`)` }}>
			      				<span>{a.name}</span>{a.unread ? '<span className="unread-count">'+a.unread+'</span>' : ''}
			  				</a>
			  				<span className="name person-name">{a.name}</span>
						</div>
					);
				})}
				<div className="member add">
				  <a href="profile-add.html" className="inner">
				  <span className="ico">+</span>
				  </a>
				</div>
			</div>
		</Hammer>
		</section>
	);
	}
}

FamilySwitcher.defaultProps = {
	family: [],
	activeMember: 0
}

