import React, {Component} from 'react';
import Nav from './Nav';
import {
    Page,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Link,
    Toolbar,
    Block,
    BlockTitle,
    List,
    ListItem,
    Row,
    Col,
    Card,
    Button
} from 'framework7-react';

import ListMeta from './ListMeta';
import BillListTopArea from './BillListTopArea';

const readyToPayList = [
  {
    'drname': 'Dr. Russaw',
    'date': 'NOV 12, 2018',
    'amount': '$157.39',
    'metaLabel': 'PAST DUE',
    'icon': 'exclamation-triangle red bill-due-warning',
    'metaClass': 'red',
    'totalAmount': '$1,280.00',
    'paymentStatus': 'past due',
    'servicesList': [
      {
        'name': 'Office Visit',
        'cost': '$80.00',
        'type': 'charge'
      },
      {
        'name': 'Emergency Room, General',
        'cost': '$600.00',
        'type': 'charge'
      },
      {
        'name': 'Crutches',
        'cost': '$200.00',
        'type': 'charge'
      },
      {
        'name': 'Drug Specific ID Detail Coding',
        'cost': '$200.00',
        'type': 'charge'
      },
      {
        'name': 'Sterile Material Supply',
        'cost': '$200.00',
        'type': 'charge'
      },
    ],
    'debitsList': [
      {
        'name': 'Insurance Discount (50%)',
        'cost': '-$640.00',
        'payment': true
      },
      {
        'name': 'Allowed Amount',
        'cost': '$640.00',
        'payment': false
      },
      {
        'name': 'Aetna Paid',
        'cost': '-$200.00',
        'payment': true
      },
      {
        'name': 'Total Due',
        'cost': '$440.00',
        'payment': false
      },
    ]
  },
  {
    'drname': 'Dr. Geddes',
    'date': 'SEP 09, 2018',
    'amount': '$1,117.62',
    'metaClass': '',
    'metaLabel': 'AMOUNT DUE',
    'totalAmount': '$1,117.62',
    'paymentStatus': 'ready to pay',
    'servicesList': [
      {
        'name': 'Office Visit',
        'cost': '$67.00',
        'type': 'charge'
      },
      {
        'name': 'X-ray',
        'cost': '$646.00',
        'type': 'charge'
      },
      {
        'name': 'Blood work',
        'cost': '$404.62',
        'type': 'charge'
      },
    ]
  },
  {
    'drname': 'Dr. Kelley',
    'date': 'AUG 27, 2018',
    'amount': '$752.28',
    'metaClass': '',
    'metaLabel': 'AMOUNT DUE',
    'totalAmount': '$752.28',
    'paymentStatus': 'ready to pay',
    'servicesList': [
      {
        'name': 'Office Visit',
        'cost': '$67.00',
        'type': 'charge'
      },
      {
        'name': 'Crutches',
        'cost': '$476.21',
        'type': 'charge'
      },
      {
        'name': 'Payment',
        'cost': '$318.82',
        'type': 'payment'
      }
    ]
  },
  {
    'drname': 'Dr. Landres',
    'date': 'AUG 06, 2018',
    'amount': '$170.00',
    'metaClass': '',
    'metaLabel': 'AMOUNT DUE',
    'totalAmount': '$170.00',
    'paymentStatus': 'ready to pay',
    'servicesList': [
      {
        'name': 'Office Visit',
        'cost': '$170.00',
        'type': 'charge'
      }
    ]
  },
  {
    'drname': 'Dr. Errico',
    'date': 'JUL 6, 2018',
    'amount': '$24.92',
    'metaClass': '',
    'metaLabel': 'AMOUNT DUE',
    'totalAmount': '$67.00',
    'paymentStatus': 'ready to pay',
    'servicesList': [
      {
        'name': 'Office Visit',
        'cost': '$67.00',
        'type': 'charge'
      },
      {
        'name': 'Payment',
        'cost': '$42.08',
        'type': 'payment'
      }
    ]
  },
  {
    'drname': 'Dr. Pachelo',
    'date': 'JUN 02, 2018',
    'amount': '$899.99',
    'metaClass': '',
    'metaLabel': 'AMOUNT DUE',
    'totalAmount': '$1,399.99',
    'paymentStatus': 'ready to pay',
    'servicesList': [
      {
        'name': 'Office Visit',
        'cost': '$67.00',
        'type': 'charge'
      },
      {
        'name': 'Stitches',
        'cost': '$476.21',
        'type': 'charge'
      },
      {
        'name': 'MRI',
        'cost': '$856.78',
        'type': 'charge'
      },
      {
        'name': 'Payment',
        'cost': '$500.00',
        'type': 'payment'
      }
    ]
  },
]
const paidList = [
  {
    'drname': 'Dr. Lemert',
    'date': 'JAN 10, 2018',
    'amount': '$432.18',
    'totalAmount': '$432.18',
    'paymentStatus': 'paid',
    'servicesList': [
      {
        'name': 'Office Visit',
        'cost': '$67.00',
        'type': 'charge'
      },
      {
        'name': 'Physical',
        'cost': '$90.39',
        'type': 'charge'
      },
      {
        'name': 'Payment',
        'cost': '$157.39',
        'type': 'payment'
      }
    ]
  },
  {
    'drname': 'Dr. Geddes',
    'date': 'MAY 09, 2017',
    'amount': '$1,399.99',
    'totalAmount': '$1,399.99',
    'paymentStatus': 'paid',
    'servicesList': [
      {
        'name': 'Office Visit',
        'cost': '$67.00',
        'type': 'charge'
      },
      {
        'name': 'Stitches',
        'cost': '$476.21',
        'type': 'charge'
      },
      {
        'name': 'MRI',
        'cost': '$856.78',
        'type': 'charge'
      },
      {
        'name': 'Payment',
        'cost': '$500.00',
        'type': 'payment'
      },
      {
        'name': 'Payment',
        'cost': '$899.99',
        'type': 'payment'
      },

    ]
  },
  {
    'drname': 'Dr. Peluse',
    'date': 'FEB 27, 2017',
    'amount': '$20.28',
    'totalAmount': '$20.28',
    'paymentStatus': 'paid',
    'servicesList': [
      {
        'name': 'Office Visit',
        'cost': '$67.00',
        'type': 'charge'
      },
      {
        'name': 'Stitches',
        'cost': '$476.21',
        'type': 'charge'
      },
      {
        'name': 'MRI',
        'cost': '$856.78',
        'type': 'charge'
      },
      {
        'name': 'Payment',
        'cost': '$500.00',
        'type': 'payment'
      }
    ]
  },
  {
    'drname': 'Dr. Landres',
    'date': 'AUG 06, 2016',
    'amount': '$140.00',
    'totalAmount': '$140.00',
    'paymentStatus': 'paid',
    'servicesList': [
      {
        'name': 'Office Visit',
        'cost': '$67.00',
        'type': 'charge'
      },
      {
        'name': 'Stitches',
        'cost': '$476.21',
        'type': 'charge'
      },
      {
        'name': 'MRI',
        'cost': '$856.78',
        'type': 'charge'
      },
      {
        'name': 'Payment',
        'cost': '$500.00',
        'type': 'payment'
      }
    ]
  },
  {
    'drname': 'Dr. Bierly',
    'date': 'JUL 6, 2016',
    'amount': '$204.92',
    'totalAmount': '$204.92',
    'paymentStatus': 'paid',
    'servicesList': [
      {
        'name': 'Office Visit',
        'cost': '$67.00',
        'type': 'charge'
      },
      {
        'name': 'Stitches',
        'cost': '$476.21',
        'type': 'charge'
      },
      {
        'name': 'MRI',
        'cost': '$856.78',
        'type': 'charge'
      },
      {
        'name': 'Payment',
        'cost': '$500.00',
        'type': 'payment'
      }
    ]
  },
  {
    'drname': 'Dr. Enciso',
    'date': 'JUN 02, 2016',
    'amount': '$52.66',
    'totalAmount': '$52.66',
    'paymentStatus': 'paid',
    'servicesList': [
      {
        'name': 'Office Visit',
        'cost': '$67.00',
        'type': 'charge'
      },
      {
        'name': 'Stitches',
        'cost': '$476.21',
        'type': 'charge'
      },
      {
        'name': 'MRI',
        'cost': '$856.78',
        'type': 'charge'
      },
      {
        'name': 'Payment',
        'cost': '$500.00',
        'type': 'payment'
      }
    ]
  },
]


const topArea = [
  {
    'type': 1,
    'metaLabel': 'NEW BILL PENDING',
    'title': 'Pacific Dental Assoc.',
    'date': 'Jan 6, 2019',
    'amount': '$152.28',
    'totalAmount': '$752.28',
    'paymentStatus': 'pending',
    'drname': 'Pacific Dental Assoc.'
  },
  {
    'type': 3,
    'children': [
      {
        'metaLabel': 'DUE FEB 12',
        'title': '$543.21',
      },
      {
        'metaLabel': 'PAST DUE',
        'title': '$157.39',
      },
      {
        'button': 'PAYMENT OPTIONS'
      },
    ],
    'billDetails': {
      ... readyToPayList[0]
    }
  },
];
export default class Bills extends Component {
  Constructor(props) {
 
  }

  componentWillMount() {
    this.$f7.panel.close('left');
  }

  render() {
    const {
    } = this.props;
    const self = this;
    return (
      <Page className="bills-page">
      { <Toolbar className="upload-toolbar">
        <Button fill className="payment-button">SCAN A PAPER BILL</Button>
      </Toolbar>}
        <Nav
        title="Bills &amp; Payments"
        active="pregnancy"
      ></Nav>
        <div className="row due">
          <div className="col-50 border-right">
            <span class="provider-name list-item">$506.21</span>
            <span class="list-label list-item meta-low">Total Due</span>
          </div>
          <div className="col-50">
            <span class="provider-name list-item warning"><i className="fa fa-exclamation-triangle red bill-due-warning"></i> $76.54</span>
            <span class="list-label list-item meta-low">Past 30 Days</span>
          </div>
        </div>
     
        <div class="toolbar tabbar">
        <div class="toolbar-inner">
          <a href="#tab-1" class="tab-link tab-link-active"><div>Ready to Pay  <span className="badge">6</span></div></a>
          <a href="#tab-2" class="tab-link">Paid</a>
        </div>
      </div>
      <div class="tabs-animated-wrap">
        <div class="tabs">
          <div id="tab-1" class="page-content tab tab-active">
              <ListMeta 
                listData={readyToPayList}
                goTo={self}>
              </ListMeta>
          </div>
          <div id="tab-2" class="page-content tab">
            <ListMeta 
                listData={paidList}
                goTo={self}>
              </ListMeta>
          </div>
        </div>
      </div>
      
      </Page>
    );
  }
};
