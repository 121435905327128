let props = {
	activeMember: 0,
	family: [
		{
			name: 'Jane',
			image: 'jane.png',
			link: 'jane',
			gender: 'Female',
			age: 42,
			birthday: 'Dec 10, 1977',
			height: '5\'2"',
			child: false,

		},
		{
			name: 'Greg',
			image: 'greg.png',
			link: 'greg',
			gender: 'Male',
			age: 45,
			birthday: 'Jun 21, 1974',
			height: '6\'1"',
			child: false,

		},
		{
			name: 'Jason',
			image: 'jason.png',
			link: 'jason',
			gender: 'Male',
			age: 15,
			birthday: 'Jan 4, 2004',
			height: '5\'10"',
			child: true,

		},
		{
			name: 'Cindy',
			image: 'cindy.png',
			link: 'cindy',
			gender: 'Female',
			age: 13,
			birthday: 'Oct 21, 2006',
			height: '4\'11"',
			child: true,

		}
	],
	mapBoxKey: 'pk.eyJ1IjoiamFyZWRzbWl0aGJsdWVqYWNrIiwiYSI6ImNqcDhucTJsMDAwbjgzdm1tZnA4cTIxcGkifQ.vqGsXyVFrSYCjiDpXbEvUQ',
	googlePlacesKey: 'AIzaSyDDiaKAJGJLxI8y1GIBpayjgo_2Q70Vl6s'
}

export default props;