import React, {Component} from 'react';
import { connect } from 'react-redux';
import { openPanelLeft, titleUpdated } from '../../actions/PanelLeftActions';
import {
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Link
} from 'framework7-react';

import props from "../globals/globals";

const mapDispatchToProps = (dispatch) => {
  return {       
    onOpenLeftPanel: () => dispatch(openPanelLeft()),
  };
};

class Nav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      logo: this.props.logo
    }

    this.openPanelLeft = this.openPanelLeft.bind(this);
    this.goToProfile = this.goToProfile.bind(this);
  }

  openPanelLeft() {
    this.$f7.panel.open('left');
    console.log(this.$f7);
  }

   goToProfile(which) {
    window.app.$f7router.navigate('/profile/', {
      props: {
        ... which
      }
    })
  }

  render() {
    const {
      active,
      onOpenLeftPanel,
      title,
      logo
    } = this.props;

    let image = 'url(assets/images/'+props.family[props.activeMember].image+')';

    return (
      <Navbar className={active != 'home' && 'not-home'}>
        <NavLeft>
          {logo && 
            <img class="logo" src="assets/images/chc_logo.png" />
          }
          {active != 'home' &&
            <Link onClick={() => this.openPanelLeft()}><i className="fa fa-bars"></i></Link>
          }
          <NavTitle>{title}</NavTitle>
        </NavLeft>
        <NavRight>
        {active != 'home' &&
          <Link onClick={() => this.goToProfile(props.family[props.activeMember])}><div className="profile-image-nav" style={{backgroundImage: image }}></div></Link>
        }
        </NavRight>
      </Navbar>
    )
  }
};

export default connect(
  null,
  mapDispatchToProps
)(Nav);
