import React, {Component} from 'react';
import Nav from './Nav';
import {
    Page,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Link,
    Toolbar,
    Block,
    BlockTitle,
    List,
    ListItem,
    Row,
    Col,
    Card,
    Button,
    Searchbar
} from 'framework7-react';

import ListMeta from './ListMeta';
import BillListTopArea from './BillListTopArea';

const readyToPayList = [
  {
    'drname': 'Dr. Russaw',
    'date': 'NOV 12, 2018',
    'amount': '$157.39',
    'metaLabel': 'PAST DUE',
    'icon': 'exclamation-triangle red bill-due-warning',
    'metaClass': 'red',
    'totalAmount': '$543.21',
    'paymentStatus': 'past due',
    'servicesList': [
      {
        'name': 'Office Visit',
        'cost': '$67.00',
        'type': 'charge'
      },
      {
        'name': 'Crutches',
        'cost': '$476.21',
        'type': 'charge'
      }
    ]
  },
  {
    'drname': 'Dr. Geddes',
    'date': 'SEP 09, 2018',
    'amount': '$1,117.62',
    'metaClass': '',
    'metaLabel': 'AMOUNT DUE',
    'totalAmount': '$1,117.62',
    'paymentStatus': 'ready to pay',
    'servicesList': [
      {
        'name': 'Office Visit',
        'cost': '$67.00',
        'type': 'charge'
      },
      {
        'name': 'X-ray',
        'cost': '$646.00',
        'type': 'charge'
      },
      {
        'name': 'Blood work',
        'cost': '$404.62',
        'type': 'charge'
      },
    ]
  },
  {
    'drname': 'Dr. Kelley',
    'date': 'AUG 27, 2018',
    'amount': '$752.28',
    'metaClass': '',
    'metaLabel': 'AMOUNT DUE',
    'totalAmount': '$752.28',
    'paymentStatus': 'ready to pay',
    'servicesList': [
      {
        'name': 'Office Visit',
        'cost': '$67.00',
        'type': 'charge'
      },
      {
        'name': 'Crutches',
        'cost': '$476.21',
        'type': 'charge'
      },
      {
        'name': 'Payment',
        'cost': '$318.82',
        'type': 'payment'
      }
    ]
  },
  {
    'drname': 'Dr. Landres',
    'date': 'AUG 06, 2018',
    'amount': '$170.00',
    'metaClass': '',
    'metaLabel': 'AMOUNT DUE',
    'totalAmount': '$170.00',
    'paymentStatus': 'ready to pay',
    'servicesList': [
      {
        'name': 'Office Visit',
        'cost': '$170.00',
        'type': 'charge'
      }
    ]
  },
  {
    'drname': 'Dr. Errico',
    'date': 'JUL 6, 2018',
    'amount': '$24.92',
    'metaClass': '',
    'metaLabel': 'AMOUNT DUE',
    'totalAmount': '$67.00',
    'paymentStatus': 'ready to pay',
    'servicesList': [
      {
        'name': 'Office Visit',
        'cost': '$67.00',
        'type': 'charge'
      },
      {
        'name': 'Payment',
        'cost': '$42.08',
        'type': 'payment'
      }
    ]
  },
  {
    'drname': 'Dr. Pachelo',
    'date': 'JUN 02, 2018',
    'amount': '$899.99',
    'metaClass': '',
    'metaLabel': 'AMOUNT DUE',
    'totalAmount': '$1,399.99',
    'paymentStatus': 'ready to pay',
    'servicesList': [
      {
        'name': 'Office Visit',
        'cost': '$67.00',
        'type': 'charge'
      },
      {
        'name': 'Stitches',
        'cost': '$476.21',
        'type': 'charge'
      },
      {
        'name': 'MRI',
        'cost': '$856.78',
        'type': 'charge'
      },
      {
        'name': 'Payment',
        'cost': '$500.00',
        'type': 'payment'
      }
    ]
  },
]
const paidList = [
  {
    'drname': 'Dr. Lemert',
    'date': 'JAN 10, 2018',
    'amount': '$157.39',
    'totalAmount': '$157.39',
    'paymentStatus': 'paid',
    'servicesList': [
      {
        'name': 'Office Visit',
        'cost': '$67.00',
        'type': 'charge'
      },
      {
        'name': 'Physical',
        'cost': '$90.39',
        'type': 'charge'
      },
      {
        'name': 'Payment',
        'cost': '$157.39',
        'type': 'payment'
      }
    ]
  },
  {
    'drname': 'Dr. Geddes',
    'date': 'MAY 09, 2017',
    'amount': '$1,399.99',
    'totalAmount': '$1,399.99',
    'paymentStatus': 'paid',
    'servicesList': [
      {
        'name': 'Office Visit',
        'cost': '$67.00',
        'type': 'charge'
      },
      {
        'name': 'Stitches',
        'cost': '$476.21',
        'type': 'charge'
      },
      {
        'name': 'MRI',
        'cost': '$856.78',
        'type': 'charge'
      },
      {
        'name': 'Payment',
        'cost': '$500.00',
        'type': 'payment'
      },
      {
        'name': 'Payment',
        'cost': '$899.99',
        'type': 'payment'
      },

    ]
  },
  {
    'drname': 'Dr. Peluse',
    'date': 'FEB 27, 2017',
    'amount': '$752.28',
    'totalAmount': '$752.28',
    'paymentStatus': 'paid',
    'servicesList': [
      {
        'name': 'Office Visit',
        'cost': '$67.00',
        'type': 'charge'
      },
      {
        'name': 'Stitches',
        'cost': '$476.21',
        'type': 'charge'
      },
      {
        'name': 'MRI',
        'cost': '$856.78',
        'type': 'charge'
      },
      {
        'name': 'Payment',
        'cost': '$500.00',
        'type': 'payment'
      }
    ]
  },
  {
    'drname': 'Dr. Landres',
    'date': 'AUG 06, 2016',
    'amount': '$170.00',
    'totalAmount': '$170.00',
    'paymentStatus': 'paid',
    'servicesList': [
      {
        'name': 'Office Visit',
        'cost': '$67.00',
        'type': 'charge'
      },
      {
        'name': 'Stitches',
        'cost': '$476.21',
        'type': 'charge'
      },
      {
        'name': 'MRI',
        'cost': '$856.78',
        'type': 'charge'
      },
      {
        'name': 'Payment',
        'cost': '$500.00',
        'type': 'payment'
      }
    ]
  },
  {
    'drname': 'Dr. Bierly',
    'date': 'JUL 6, 2016',
    'amount': '$24.92',
    'totalAmount': '$24.92',
    'paymentStatus': 'paid',
    'servicesList': [
      {
        'name': 'Office Visit',
        'cost': '$67.00',
        'type': 'charge'
      },
      {
        'name': 'Stitches',
        'cost': '$476.21',
        'type': 'charge'
      },
      {
        'name': 'MRI',
        'cost': '$856.78',
        'type': 'charge'
      },
      {
        'name': 'Payment',
        'cost': '$500.00',
        'type': 'payment'
      }
    ]
  },
  {
    'drname': 'Dr. Enciso',
    'date': 'JUN 02, 2016',
    'amount': '$542.66',
    'totalAmount': '$542.66',
    'paymentStatus': 'paid',
    'servicesList': [
      {
        'name': 'Office Visit',
        'cost': '$67.00',
        'type': 'charge'
      },
      {
        'name': 'Stitches',
        'cost': '$476.21',
        'type': 'charge'
      },
      {
        'name': 'MRI',
        'cost': '$856.78',
        'type': 'charge'
      },
      {
        'name': 'Payment',
        'cost': '$500.00',
        'type': 'payment'
      }
    ]
  },
]


const topArea = [
  {
    'type': 1,
    'metaLabel': 'NEW BILL PENDING',
    'title': 'Pacific Dental Assoc.',
    'date': 'Jan 6, 2019',
    'amount': '$152.28',
    'totalAmount': '$752.28',
    'paymentStatus': 'pending',
    'drname': 'Pacific Dental Assoc.'
  },
  {
    'type': 3,
    'children': [
      {
        'metaLabel': 'DUE FEB 12',
        'title': '$543.21',
      },
      {
        'metaLabel': 'PAST DUE',
        'title': '$157.39',
      },
      {
        'button': 'PAYMENT OPTIONS'
      },
    ],
    'billDetails': {
      ... readyToPayList[0]
    }
  },
];
export default class Estimate extends Component {
  Constructor(props) {
 
  }

  componentWillMount() {
    this.$f7.panel.close('left');
  }

  render() {
    const {
    } = this.props;
    const self = this;
    return (
      <Page className="bills-page estimate">
        <Nav
        title="How much will it cost?"
        active="pregnancy"
      ></Nav>


        <Block strong className="blue">
          <BlockTitle white>What is your coverage for a procedure?</BlockTitle>
          <Searchbar 
            type="search" 
            placeholder="Find..." 
            clearButton={true} />

            {/*<a fill raised href="/map-panel/">Bottom Panel</a>*/}
            <span className="white">Procedure, Speciality, Doctor name...</span>

            <div className="white location"><i className="icon icon-pin"></i> Current Location</div>
        </Block>
      <Block className="no-margin marg-top">
        <Card
          title="HSA AVAILABLE TO SPEND"
          className="spending-card"
        >
          <div className="row">
          <div className="col-50 border-right">
            <span className="bigger dollar-amount">$345.00</span>
            <span>Fidelity</span>
          </div>
          <div className="col-50">
            <span className="bigger dollar-amount">$768.12</span>
            <span>HealthEquity</span>
          </div>
          </div>
        </Card>
        <Card
          title="SPENDING THIS YEAR"
          className="spending-card"
        >
          <div className="row abs-reader">
          <div className="reader"></div>
          <div className="col-33 blue t-left">
            <span className="dollar-amount">$2,828</span>
            <span>Spent</span>
          </div>
          <div className="col-33">
            <span className="dollar-amount">$3,600</span>
            <span>Deductible</span>
          </div>
          <div className="col-33 t-right">
            <span className="dollar-amount">$6,500</span>
            <span>Out-of-Pocket max</span>
          </div>
          </div>
        </Card>
      </Block>
     
      
      </Page>
    );
  }
};
