import HomePage from './components/pages/HomePage';
// import HomeContainer from './containers/HomeContainer';
import AboutPage from './components/pages/AboutPage';
import FormPage from './components/pages/FormPage';
import BillPage from './components/pages/Bill';
import PayPage from './components/pages/Pay';
import PaidPage from './components/pages/Paid';
import NotFoundPage from './components/pages/NotFoundPage';

import PanelLeftContainer from './containers/PanelLeftContainer';

import MapPanel from './components/pages/MapPanel';
import FindCare from './components/pages/FindCare';
import HealthHistory from './components/pages/HealthHistory';
import Bills from './components/pages/Bills';
import CareTeam from './components/pages/CareTeam';
import Profile from './components/pages/Profile';
import Appointments from './components/pages/Appointments';
import Medications from './components/pages/Medications';
import Estimate from './components/pages/Estimate';

export default [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/panel-left/',
    panel: {
      component: PanelLeftContainer,
    },
  },
  // {
  //   path: '/panel-right/',
  //   component: PanelRightPage,
  // },
  {
    path: '/map-panel/',
    component: MapPanel,
  },
  {
    path: '/about/',
    component: AboutPage,
  },
  {
    path: '/form/',
    component: FormPage,
  },
  {
    path: '/bill/',
    component: BillPage,
  },
  {
    path: '/find-care/',
    component: FindCare,
  },
  {
    path: '/records/',
    component: HealthHistory,
  },
  {
    path: '/bills/',
    component: Bills,
  },
  {
    path: '/pay/',
    component: PayPage,
  },
  {
    path: '/paid/',
    component: PaidPage,
  },
  {
    path: '/estimates/',
    component: Estimate,
  },
  {
    path: '/care-team/',
    component: CareTeam,
  },
  {
    path: '/profile/',
    component: Profile,
  },
  {
    path: '/appointments/',
    component: Appointments,
  },
  {
    path: '/medications/',
    component: Medications,
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];
