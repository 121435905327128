import React, { Component } from 'react';
import { Page, Navbar, Block, Link, ListItem, ListInput, Icon, List, Toolbar, Radio, Button } from 'framework7-react';
import Nav from './Nav';

export default class Paid extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      drname,
      paymentStatus
    } = this.props;
    let self = this;
    return (
      <Page className="pay-detail bill-detail paid-confirmation">
        <Nav
          title={"Payment Confirmation"}
          active="pregnancy"
        ></Nav>


       <Block className="services-list-block">
           <i className="fa fa-check"></i> 
           <div className="block-title block-title payment-title">Payment Complete</div>
           <div className="block-title block-title payment-sub-title">You can cancel your payment within 24 hours.</div>
       </Block>

        <Block className="bill-detail-header row">
          <div className="col-70">
            <span className="small meta">JAN 7, 2019</span>
            <div className="provider-name">{this.props.drname ? this.props.drname : 'Dr. Russaw'}</div>
            <div className="ref-number">REF# 1524-BN65-9844-1002</div>
            <div className="ref-number">PAID</div>
          </div>
          <div className="col-30">
            <div className="provider-name">{this.props.amount ? this.props.amount : '$157.39'}</div>
          </div>
       </Block>

         <Toolbar className="payment">
           <Button fill className="payment-button" href="/bills/">Pay Another Bill</Button>
        </Toolbar>
      </Page>
    );
  }
}

Paid.defaultProps = {
  paymentType: 'card',
  paymentTypes: [
      {
        name: 'Credit/Debit',
        dataName: 'card',
        checked: 'checked'
      },
      {
        name: 'Bank Account',
        dataName: 'account',
        checked: ''
      },
      {
        name: 'HSA Balance ($1,113.12)',
        dataName: 'hsa',
        checked: ''
      }
    ]
}