import React from 'react';
import Nav from './Nav';
import {
    Page,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Link,
    Toolbar,
    Block,
    BlockTitle,
    List,
    ListItem,
    Row,
    Col,
    Button
} from 'framework7-react';

export default () => (
  <Page>
    <Nav
    title="Care Team"
    active="pregnancy"
  ></Nav>
   { <Toolbar>
      <Link><i className="icon-plus"></i> Add caregiver or location</Link>
      <Link>Filter</Link>
    </Toolbar>}
    <List className="">
      <ListItem className="nav-item" link="" title="UCSF Benioff Hospital"></ListItem>
      <ListItem className="nav-item" link="" title="Dr. Allen R. Picket"></ListItem>
      <ListItem className="nav-item" link="" title="Dr. Lisa Watson"></ListItem>
      <ListItem className="nav-item" link="" title="Dr. Linda Turnbottom"></ListItem>
    </List>
  </Page>
);
