import React, {Component} from 'react';
import props from "../globals/globals";
import PropTypes from 'prop-types';
import {
    List,
    ListItem,
    Row,
    Link,
    Col,
    Button,
	ActionsButton,
    Actions,
    ActionsGroup,
    ActionsLabel,
    ListInput,
} from 'framework7-react';


export default class BillListTopArea extends Component {
	constructor(props) {
		super(props);

		let selfRef = this;

		this.props = props;
		this.goToBill = this.goToBill.bind(this);
	};

	goToBill(which, goTo) {
		goTo.$f7router.navigate('/bill/', {
			props: {
				goTo,
				... which
			}
		})
	}
	goToPay(which, goTo) {
		goTo.$f7router.navigate('/pay/', {
			props: {
				goTo,
				... which
			}
		})
	}


	render() {
		const {
			listData,
			clickAction,
			goTo
		} = this.props;
		var self = this;
		return (
			<List className="no-margin list-meta">
			{listData.map(function(row, key){
				if(row.type === 1) {
					return (
			      		<ListItem key={key} link className="nav-item row dark-blue-bg"
			      			onClick={() => self.goToBill(row, goTo)}
			      		>
			      			<div className="col-100">
			      				<span className="bold list-label dark">{row.metaLabel}</span>
			      				<span class="provider-name list-item">{row.title}</span>
			      				<span class="list-label list-item meta-low">{row.metaLow}</span>
			      			</div>
			      		</ListItem>
			      	);
				} else if (row.type === 3) {
					return (
						<ListItem className="no-pad">
							<ListItem key={key} className="nav-item row white-please">
								<div className="col-100">
				      				<span className="bold list-label dark">NEW BILL RECEIVED</span>
				      				<span class="provider-name list-item">Dr. Russaw</span>
				      				<span class="list-label list-item meta-low">Physical Therapy Session 11/19/18</span>
				      			</div>
				      		</ListItem>
							<ListItem key={key} className="nav-item row three-col">
				      			<div className="col-50">
				      				<span className="bold list-label dark">{row.children[0].metaLabel}</span>
				      				<span class="provider-name list-item">{row.children[0].title}</span>
				      				<span class="list-label list-item meta-low">{row.children[0].metaLow}</span>
				      			</div>
				      			<div className="col-50">
				      				<span className="bold list-label t-red">{row.children[1].metaLabel}</span>
				      				<span class="provider-name list-item"><i className="fa fa-exclamation-triangle red bill-due-warning"></i> {row.children[1].title}</span>
				      				<span class="list-label list-item meta-low">{row.children[1].metaLow}</span>
				      			</div>
				      		</ListItem>
				      		<ListItem className="row">
				      			<div className="col-100">
				      				<Button fill onClick={() => self.refs.actionsOneGroup.open()} className="button list-meta">{row.children[2].button}</Button>
				      			</div>
				      		</ListItem>
			      		</ListItem>
					);
				}
		      }

		      )
			}
			<Actions ref="actionsOneGroup">
		        <ActionsGroup>
		          <ActionsLabel>Payment Options</ActionsLabel>
		          <ActionsButton onClick={() => self.goToPay(listData[1].billDetails, goTo)}>Pay Entire Balance ($543.21)</ActionsButton>
		          <ActionsButton onClick={() => self.goToPay(listData[1].billDetails, goTo)}>Pay Past Due Amount ($157.39)</ActionsButton>
		          <ActionsButton onClick={() => self.refs.actionsTwoGroup.open()}>Pay Another Amount</ActionsButton>
		          <ActionsButton >
		          	<Link onClick={() => self.goToBill(listData[1].billDetails, goTo)}>Bill Details</Link>
		          </ActionsButton>
		          <ActionsButton color="red">Cancel</ActionsButton>
		        </ActionsGroup>
		      </Actions>

		      <Actions ref="actionsTwoGroup">
		        <ActionsGroup>
		          <ActionsLabel>Payment A Custom Amount</ActionsLabel>
		          <ActionsLabel>Enter Amount Here<ListInput type="number" placeholder="$..."></ListInput></ActionsLabel>
		          <ActionsButton color="red">Cancel</ActionsButton>
		        </ActionsGroup>
		      </Actions>
		    </List>
		);
	};
};

BillListTopArea.defaultProps = {
	listData:  []
}

// export default MapView;