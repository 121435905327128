import React, {Component} from 'react';
import { Panel, Page, Navbar, Block, BlockTitle, List, ListItem, Link } from 'framework7-react';

import { getPanelState } from '../../selectors/PanelLeftSelectors';

class PanelLeft extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: 'Health App'
    }

    this.getTitle = this.getTitle.bind(this);
  }

  componentDidMount() {
    this.getTitle(this.props.title);
  }

  getTitle(title) {
    this.setState({
      name: title
    })
  }

  render() {
    const {
      onGoToAbout,
      onGoToForm,
      onClosePanelLeft,
      onTitleUpdated
    } = this.props;
    return (
      <Panel className="nav-panel" left reveal onPanelBackdropClick={onClosePanelLeft}>
        <Page>
          <Navbar title="Health App" />
          <List className="nav-list">
            <ListItem className="nav-item" link title="Home" link="/"><i className='fa fa-home'></i></ListItem>
            <ListItem className="nav-item" link title="Find Care" link='/find-care/'><i className="icon-new icon-new_findcare_lg"></i></ListItem>
            <ListItem className="nav-item" link title="Estimate Costs" link='/estimates/'><i className="icon-new icon-new_paper_dollar"></i></ListItem>
            <ListItem className="nav-item" link title="Health History" link='/records/'><i className="icon-new icon-new_history_lg"></i></ListItem>
            
            <ListItem className="nav-item" link title="Bills &amp; Payments" link='/bills/'><i className='icon-cost-paper'></i></ListItem>
            <ListItem className="nav-item" link title="Care Team" link="/care-team/"><i className='icon-new_careteam'></i></ListItem>
            <ListItem className="nav-item" link title="Medications" link="/medications/"><i className='icon-new icon-new_medications_sm'></i></ListItem>
            {/*<ListItem link title="Profile" link="/profile/"></ListItem>*/}
            {/*<ListItem link title="Auto-fill Medical Forms" link="/forms/"></ListItem>*/}
          </List>
        </Page>
      </Panel>
    );
  };
};

const mapStateToProps = (state) => {
  return getPanelState(state);
};

export default PanelLeft;