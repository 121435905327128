import React, {Component} from 'react';
import { Navbar, Page, NavTitle, List, ListItem, Block, Button, Popup, Link, NavRight } from 'framework7-react';
import Nav from './Nav';

export default class Appointments extends Component {
  constructor(props) {
    super(props);

    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    this.state = {
      today: new Date(year, month, day),
      events: [
        {
          date: new Date(year, month, day),
          meta: "Follow up appointment with Dr. Russaw regarding your test results.",
          hours: 12,
          minutes: 30,
          ordinal: 'PM',
          title: 'Folllow Up with Dr. Russaw',
          color: '#2196f3',
          from: '',
          to: ''
        },
        {
          date: new Date(year, month, day),
          meta: "primrosed muirburn applejohn grihyasutra Marcionitish ahura phagocytolytic cyclopedically nototribe Ornithogaean whincow Elishah Croaker superworldly quoniam feloness besieging postmeiotic vulpicidal pipiri theologicomilitary cardiosclerosis prolongably iamb",
          hours: 1,
          minutes: 0,
          ordinal: 'PM',
          title: 'X-ray @ Kaiser',
          color: '#4caf50',
        },
        {
          date: new Date(year, month, day + 2),
          meta: "erodent vorticism indigenous myxoid osteodiastasis diphtheritic aplanospore storify theognostic elucubrate xanthopicrite indulto gastrocele wincingly complicate capman cuttlebone cyclic symphynote intranslatable tufa linin gossiphood thrice",
          hours: 2,
          minutes: 0,
          ordinal: 'PM',
          title: 'Pick up Albuterol',
          color: '#2196f3',
        },
        {
          date: new Date(year, month, day + 2),
          meta: "interarboration spadiciflorous prickmadam mercurial ectasis zymophoric rauque lira Cyprian aburabozu pantometer roughleg helpworthy Adonis extravasate syntexis entoderm rhizopodan loric amylopsin Scomber Shaula saccade Hansardize",
          hours: 9,
          minutes: 30,
          ordinal: 'AM',
          title: 'PT',
          color: '#ff9800',
        },
        {
          date: new Date(year, month, day + 12),
          meta: "laughy follower floriform cullage bratstvo cairngorum militantness encash salvor boarship discoblastic pantheic metal procereal rectified oratorian pistolwise flagellar Acroceridae perigynium caprylone Osage predoubtful uncompromisingness",
          hours: 9,
          minutes: 0,
          ordinal: 'AM',
          title: 'Sally\'s pre-trip physical exam',
          color: '#ff9800',
        },
      ],
      eventItems: [],
    };


    this.handleEventClick = this.handleEventClick.bind(this);
  }

  handleEventClick(index) {
    this.state.eventItems[index].popupOpened = !this.state.eventItems[index].popupOpened;
    this.forceUpdate();
  }

  render() {
    console.log(this.state);
    return (
      <Page onPageInit={this.onPageInit.bind(this)} onPageBeforeRemove={this.onPageBeforeRemove.bind(this)}>
       <Nav
        title="Appointments"
        active="pregnancy"
      ></Nav>
        <Block
          id="calendar"
          strong
          className="no-padding no-hairline-top"
        ></Block>
        <List
          id="calendar-events"
          noHairlines
          className="no-margin no-ios-left-edge"
        >
          {this.state.eventItems.map((item, index) => (
            <ListItem
              key={index}
              title={item.title}
              after={item.time+item.ordinal}
              onClick={() => {this.handleEventClick(index)}}
            >
              <div className="event-color" style={{'backgroundColor': item.color}} slot="before-title"></div>
              <Popup className="demo-popup" opened={this.state.eventItems[index].popupOpened} >
                <Page>
                  <Navbar title={item.title}>
                    <NavRight>
                      <Link onClick={() => this.handleEventClick(index)}>Close</Link>
                    </NavRight>
                  </Navbar>

                  <Block>
                    <span className="item-title">{item.title}</span>
                    <span className="item-time">{item.time+item.ordinal}</span>

                    <p>
                      {item.meta}
                    </p>
                  </Block>
                </Page>
              </Popup>
            </ListItem>
             
          ))}
          {this.state.eventItems.length === 0 && (
            <ListItem>
              <span className="text-color-gray" slot="title">No events for this day</span>
            </ListItem>
          )}
        </List>

        <Button fill raised link className="new-appointment-button">Schedule New</Button>
      </Page>
    );
  }
  renderEvents(calendar) {
    const self = this;
    const currentDate = calendar.value[0];
    const currentEvents = self.state.events
      .filter(event => (
        event.date.getTime() >= currentDate.getTime() &&
        event.date.getTime() < currentDate.getTime() + 24 * 60 * 60 * 1000
      ));

    const eventItems = [];
    if (currentEvents.length) {
      currentEvents.forEach((event) => {
        const hours = event.hours;
        let minutes = event.minutes;
        if (minutes < 10) minutes = '0'+minutes;
        eventItems.push({
          title: event.title,
          time: hours+':'+minutes,
          color: event.color,
          ordinal: event.ordinal,
          uid: event.uid,
          popupOpened: false,
          meta: event.meta,
        });
      });
    }
    self.setState({
      eventItems,
    });
  }
  onPageInit(e, page) {
    console.log(this.state.events);
    const self = this;
    const app = self.$f7;
    const $ = self.$$;
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    self.calendar = app.calendar.create({
      containerEl: '#calendar',
      toolbar: true,
      value: [self.state.today],
      events: self.state.events,
      on: {
        init(calendar) {
          $('.navbar-calendar-title').text(`${monthNames[calendar.currentMonth]}, ${calendar.currentYear}`);
          // app.navbar.size(page.navbarEl);
          calendar.$el.addClass('no-ios-right-edge');
          self.renderEvents(calendar);
        },
        monthYearChangeStart(calendar) {
          $('.navbar-calendar-title').text(`${monthNames[calendar.currentMonth]}, ${calendar.currentYear}`);
          // app.navbar.size(page.navbarEl);
        },
        change(calendar) {
          self.renderEvents(calendar);
        },
        dayClick: function(calendar, day) {
          // console.log(day);
        }
      },
    });

    window.calendar = self.calendar;
  }
  onPageBeforeRemove() {
    const self = this;
    self.calendar.destroy();
  }
};