import React from 'react';
import Nav from './Nav';
import { Page, Navbar, Block } from 'framework7-react';

export default () => (
  <Page>
    {/*<Navbar title="Not found" backLink="Back" onClick={() => window.app.$f7router.back()} />*/}
    <Nav
        title="Page Not Found"
        active="not-found"
      ></Nav>
    <Block strong>
      <p>Sorry</p>
      <p>Requested content not found.</p>
    </Block>
  </Page>
);
