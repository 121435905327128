import { connect } from 'react-redux'

import PanelLeft from '../components/modals/PanelLeft';
import { closePanelLeft, goToAboutPageFromLeftPanel, goToFormPageFromLeftPanel, titleUpdated } from '../actions/PanelLeftActions';

const mapDispatchToProps = (dispatch) => {
  return {
    onClosePanelLeft: () => dispatch(closePanelLeft()),
    onGoToAbout: () => dispatch(goToAboutPageFromLeftPanel()),
    onGoToForm: () => dispatch(goToFormPageFromLeftPanel()),
    onTitleUpdated: (name) => dispatch(titleUpdated(name)),
  };
};

export default connect(null, mapDispatchToProps)(PanelLeft);
